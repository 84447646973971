import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import './HomePage.css';
import SplashPage from '../SplashPage/SplashPage';
import HeaderMicrosoftLoginPage from '../MicrosoftPage/HeaderMicrosoftLoginPage/HeaderMicrosoftLoginPage';
import MicrosoftHomePage from '../MicrosoftPage/MicrosoftHomePage/MicrosoftHomePage';

const HomePage = ({ history }) => {

    const [showPage, setPage] = useState(false);

    useEffect(() => {
        document.title = "MyMenuConcierge-Homepage";
        let userDetails = JSON.parse(localStorage.getItem("userDetails"));
        if (userDetails && userDetails.bEmailConfirmed) {
            //history.push('/dashboard');
            window.location.href = '/dashboard';
        }
    }, []);

    return (
        <div className="App">
            {showPage &&
                <SplashPage />
            }
            <div className="landing_wraper screen2 login-wraper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="screen2_inner">
                                <img src="/static/img/logo_transparent.png"  alt="landinglogo"/>
                                {/*<div className="home_top">
                                  <h1 className="text-center">Menu Choices Personalized For You</h1>
                      <p className="home_subtxt mobile_show">Click below to create your <br/>personalized menu preferences</p>
                                  <h1 className="text-center display-3">WELCOME!</h1>
                    </div>*/}
                                <div class="common_secinner fit_content"><h1 className="text-center">A Giant Leap Forward for Health, Performance and Longevity​</h1></div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-12">
                            <div className="screen2_inner">

                                <div className="continue_search_result fill_width">
                                    <div onClick={e => window.location.href = '/login'}>LOGIN</div>
                                </div>
                                {/*<br />*/}
                                {/*<div className="continue_search_result fill_width">*/}
                                {/*    <div onClick={e => window.location.href = '/register'}>CREATE ACCOUNT</div>*/}
                                {/*</div>*/}
                                {/*<br /><br /><br />*/}
                                {/*<div>*/}

                                {/*    <p className="text-white">*/}
                                {/*        */}{/* <img src="/static/img/microsoft_logo.png" alt="microsoft_logo"></img> */}
                                {/*        To see a demo and learn more visit:<br></br>*/}
                                {/*        */}{/* <span>Provided by Microsoft Travel</span> */}
                                {/*        <a href="https://MyMenuUSA.com" target="_blank">*/}
                                {/*            <h3 className="text-white-normal">MyMenuUSA.com</h3>*/}
                                {/*        </a>*/}
                                {/*    </p>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
export default withLocalize(withRouter(HomePage));

